import React, { Component } from "react";
import "./UserLinks.scss";

class UserLinks extends Component {
  getLinkElements() {
    const { userLinks } = this.props.config;
    const { labeled } = this.props;
    return userLinks.map(link => (
      <a href={link.url} key={link.label} className="nav-link">{link.label}</a>
    ));
  }

  render() {
    const { userLinks } = this.props.config;
    if (!userLinks) {
      return null;
    }
    return <nav className="user-links">{this.getLinkElements()}</nav>;
  }
}

export default UserLinks;
