const config = {
  siteTitle: "frillish", // Site title.
  siteTitleShort: "frillish", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "", // Alternative site title for SEO.
  siteLogo: "/site/profile.jpg", // Logo used for SEO and manifest.
  profilePhoto: "/site/shirokuma.png", // Logo used for SEO and manifest.
  siteUrl: "https://frill.cursola.net//", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "a blog", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "RSS", // Title of the RSS feed
  siteFBAppID: "", // FB Application ID for using app insights. default: 1825356251115265
  googleAnalyticsID: "", // GA tracking ID. default: UA-47311644-5
  disqusShortname: "", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 3, // Amount of posts displayed per listing page.
  userName: "cheerie", // Username to display in the author segment.
  userEmail: "", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "Blog",
      url: "/",
      iconClassName: ""
    },
    // {
    //   label: "About",
    //   url: "/about/",
    //   iconClassName: ""
    // },
    {
      label: "@_frillish",
      url: "https://www.instagram.com/_frillish/",
      iconClassName: ""
    }
    // {
    //   label: "KonMari",
    //   url: "/konmari-overview/",
    //   iconClassName: ""
    // },
  ],
  copyright: "©2020-2023 frillish", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  // config.pathPrefix = "";
  config.pathPrefix === "/"
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
